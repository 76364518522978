<script>
export default {
  name: "tpl",
  props: ['edit_infoNewItem', 'reloadlist', 'tabActiveKey'],
  data() {
    return {
      search_input: '',
      tpl_active: 0,
      tpl_page: 1,
      tpl_count: 1,
      tpl_list: [],
      tpl_size: 10,
      table_show: false,
      options: {
        container: 'luckysheet',
        lang: 'zh',
        title: '',
        showinfobar: false,
        showtoolbar: false,
        column: 50,
        row: 50,
        showstatisticBar: false,
        enableAddRow: false,
        enableAddBackTop: false,
        userInfo: false,
        cellRightClickConfig: {
          copy: false, // 复制
          copyAs: false, // 复制为
          paste: false, // 粘贴
          insertRow: false, // 插入行
          insertColumn: false, // 插入列
          deleteRow: false, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: false, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: false, // 行高
          columnWidth: false, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: false, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false // 设置单元格格式
        },
        showsheetbar: false,
        sheetFormulaBar: false
      },
    }
  },
  mounted() {
    console.log(this.edit_infoNewItem)
    this.tpl_active = Number(JSON.parse(JSON.stringify(this.edit_infoNewItem.tpl_id)));
    this.getTplList();
  },
  watch: {
    tabActiveKey() {
      this.tpl_active = Number(JSON.parse(JSON.stringify(this.edit_infoNewItem.tpl_id)));
      this.getTplList();
    },
  },
  methods: {
    tplItemClick(id) {
      this.tpl_active = id
      this.$sa0.post({
        url: this.$api('获取原始记录单表格结构数据'), data: {
          id: this.tpl_active
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.table
            this.drawTpl(table ? {sheets: [JSON.parse(table)]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    chooseTpl() {
      this.$sa0.post({
        url: this.$api('检测项目绑定原始记录单模板'), data: {
          id: this.edit_infoNewItem.id,
          tplid: this.tpl_active,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
            this.reloadlist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    inputSearch() {
      this.tpl_page = 1;
      this.getTplList();
    },
    onPageChange(e) {
      this.tpl_page = e;
      this.getTplList();
    },
    drawTpl(exportJson = false) {

      this.table_show = false;
      setTimeout(() => {
        this.table_show = true;

        setTimeout(() => {
          let options = this.options
          if (exportJson) {
            options.data = exportJson.sheets
          }
          luckysheet.destroy();
          luckysheet.create(options);
        })
      })
    },
    getTplList() {
      // 报告模板分页列表
      this.$sa0.post({
        url: this.$api('原始记录单模板分页列表'),
        data: {
          page: this.tpl_page,
          search: this.search_input

        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tpl_page = response.data.page;
            this.tpl_count = response.data.count;
            this.tpl_list = response.data.list;
            this.tpl_size = response.data.size;
            if (!this.tpl_active) this.tpl_active = this.tpl_list[0].id;
            this.tplItemClick(this.tpl_active);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })

    },
  }
}
</script>
<template>
  <div class="tpl_box">
    <div class="tpl_list_wrapper" lay-size="sm">
      <table class="layui-table table_title">
        <thead>
        <tr>
          <th>原始记录单模板列表</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style="padding: 5px 0;">
            <a-input-search v-model="search_input" enter-button="搜索" @search="inputSearch()"/>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="tpl_list_box">
        <table class="layui-table">
          <tbody>
          <tr @click="tplItemClick(tpl_item.id)" v-for="(tpl_item,tpl_key) in tpl_list" :key="tpl_key">
            <td class="tpl_item_wrapper" :class="[tpl_item.id === tpl_active?'tpl_active_wrapper':'']">{{
                tpl_item.name
              }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="tpl_page_wrapper">
        <table class="layui-table">
          <tbody>
          <tr>
            <td>
              <a-pagination simple :current="tpl_page" :pageSize="tpl_size" @change="onPageChange" :total="tpl_count"
                            v-if="tpl_count>tpl_size" size="small"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="tpl_table_wrapper">
      <div v-if="table_show" class="luckysheet_wrapper">
        <div class="t_cover"></div>
        <div id="luckysheet" class="luckysheet_wrapper"></div>
      </div>
      <div style="text-align: right;margin-top: 5px;">
        <a-button type="primary" @click="chooseTpl()">保存</a-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.t_cover {
  position: absolute;
  top: 0;
  bottom: 36px;
  left: 0;
  right: 12px;
  background: #000;
  z-index: 999999;
  opacity: 0;
}

.luckysheet_wrapper {
  position: relative;
  vertical-align: top;
  display: inline-block;
  width: 900px;
  height: calc(100vh - 200px);
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

.tpl_active_wrapper {
  background: #0092fa30;
}

.tpl_table_wrapper {
  vertical-align: top;
  display: inline-block;
}

.tpl_item_wrapper {
  cursor: pointer;
}

.table_title {
  position: relative;
  z-index: 1;
}

.tpl_list_wrapper {
  vertical-align: top;
  display: inline-block;
  width: 200px;
  height: calc(100vh - 170px);
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

.tpl_page_wrapper {
  position: relative;
  transform: translateY(-30px);
}

.tpl_list_box {
  position: relative;
  transform: translateY(-20px);
  width: 200px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
</style>
