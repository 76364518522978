<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${edit_infoNewItem.id===0?'新建':'编辑'}检测项目`" width="100%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <div class="nav-tab">
                    <a-tabs :active-key="tabActiveKey" @change="onTableChange">
                        <!------------------------------------------检验仪器
                        ----------------------------------->
                        <a-tab-pane key="1" tab="基础信息">
                            <a-form v-bind="formA">
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="检验项目" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                            <!--                                            <a-auto-complete v-model="formA.item_name" :data-source="dataSource.ItemName" placeholder="请输入检验项目名称" @search="onSearch1"/>-->
                                            <a-auto-complete v-model="formA.item_name" :data-source="dataSource.ItemName" placeholder="请输入检验项目名称" :filter-option="filterOption" @focus="onSearch1"/>


                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-item label="方法类别" :label-col="{ span: 4 }" :wrapper-col="{ span: 10 }">
                                            <a-cascader v-model:default-value="defaultValue.MethodCategoryIds" :options="dataSource.MethodCategory" placeholder="请选择" @change="onChangeMethodCategory"/>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="检测方法" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                            <!--                                            <a-auto-complete v-model="formA.method_name" :data-source="dataSource.MethodName" placeholder="请输入检测方法" @search="onSearchMethodName"/>-->
                                            <!--                                            <a-auto-complete v-model="formA.method_name" :data-source="dataSource.MethodName" placeholder="请输入检验检测方法" @search="onSearch2"/>-->
                                            <a-auto-complete v-model="formA.method_name" :data-source="dataSource.MethodName" placeholder="请输入检验检测方法" :filter-option="filterOption" @focus="onSearch2"/>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <!--  {{formA.sample_type_ids}}-->

                                        <a-form-item label="资质" :label-col="{ span: 4 }" :wrapper-col="{ span: 10 }">
                                            <a-select v-model="arr" @change="selectUpdate" mode="multiple" style="width: 300px" placeholder="请选择资质" option-label-prop="label">
                                                <a-select-option v-for="(i,k) in zizhi_item_list" :value="i.id" :label="i.name">
                                                    {{ i.name}}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="检测标准名称" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                            <!--                                            <a-auto-complete v-model="formA.check_bz_name" :data-source="dataSource.CheckBZName" placeholder="请输入检测方法" @search="onSearchCheckBZName" @select="onSelectCheckBZName"/>-->
                                            <!--                                            <a-auto-complete v-model="formA.check_bz_name" :data-source="dataSource.CheckBZName" placeholder="请输入检测方法" @search="onSearch4" @select="onSelectCheckBZName"/>-->
                                            <a-auto-complete v-model="formA.check_bz_name" :data-source="dataSource.CheckBZName" placeholder="请输入检测方法" :filter-option="filterOption" @focus="onSearch4" @select="onSelectCheckBZName"/>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-item label="检测标准编号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                                            {{ formA.serial }}
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="检测标准细则" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                            <!--                                            <a-auto-complete v-model="formA.xz_serial" :data-source="dataSource.XZSerial" placeholder="输入检测标准细则编号" @search="onSearchXZSerial"/>-->
                                            <!--                                            <a-auto-complete v-model="formA.xz_serial" :data-source="dataSource.XZSerial" placeholder="输入检测标准细则编号" @search="onSearch3"/>-->
                                            <a-auto-complete v-model="formA.xz_serial" :data-source="dataSource.XZSerial" placeholder="输入检测标准细则编号" :filter-option="filterOption" @focus="onSearch3"/>

                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-item label="状态" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                                            <a-switch v-model="formA.status"/>
                                            <span v-if="formA.status===true" style="margin-left: 10px">启用</span>
                                            <span v-else style="margin-left: 10px">停用</span>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                            <a-input type="textarea" v-model="formA.remark" style="height: 200px"/>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="8">
                                        <a-form-item label="" :label-col="{ span: 5 }" :wrapper-col="{ span: 10, offset: 5 }">
                                            <a-button type="primary" @click="doClickA()">保存</a-button>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </a-form>
                        </a-tab-pane>
                        <!------------------------------------------检验仪器
                        ----------------------------------->
                        <a-tab-pane key="2" tab="检验仪器" :disabled="tab_disabled">
                            <a-row>
                                <a-col :span="16">
                                    <a-space>
                                        <a-button type="primary" @click="TJYQ = true">添加仪器</a-button>
                                        <a-button type="danger" @click="delSelectedyiqi">移除所选</a-button>
                                    </a-space>
                                    <table class="layui-table" lay-size="sm">
                                        <thead>
                                        <tr>
                                            <th>
                                                <a-space>
                                                    <a-checkbox :checked="defaultValue.ApparatusCheckAll" @click="ApparatusCheckAll"></a-checkbox>
                                                    全选
                                                </a-space>
                                            </th>
                                            <th>仪器编号</th>
                                            <th>仪器名称</th>
                                            <th>仪器分类</th>
                                            <th>操作</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item,key) in Apparatus_list_list" v-if="item.selected">
                                            <td>
                                                <a-checkbox v-model="item.check"></a-checkbox>
                                            </td>
                                            <td>{{ item.serial_num }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.type_name }}</td>
                                            <td>
                                                <a-space>
                                                    <a-button type="danger" size="small" @click="delyiqi(item.id)">移除
                                                    </a-button>
                                                    <!--                          <a-button type="primary" size="small" @click="edityiqi(item.id)">编辑模板</a-button>-->
                                                </a-space>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </a-col>
                            </a-row>
                            <a-row style="margin-top: 25px" v-if="TJYQ">
                                <a-col :span="8">
                                    <a-form-item label="" style=" margin-bottom: 0">
                                        <a-button @click="addyiqi()" type="primary">添加选中仪器</a-button>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="8" style="text-align: right">
                                    <a-form layout="inline" :modal="SearchApparatus">
                                        <a-form-item label="" style=" margin-bottom: 0">
                                            <a-select style="width: 200px" placeholder="请选择仪器分类" v-model="SearchApparatus.type">
                                                <a-select-option :value="item.id" v-for="(item,index) in ApparatusClassify" :key="index">
                                                    {{ item.title }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item label="" style=" margin-bottom: 0">
                                            <a-input style="width: 200px" placeholder="请输入仪器名称或编号" v-model="SearchApparatus.type_name"></a-input>
                                        </a-form-item>
                                        <a-form-item label="" style=" margin-bottom: 0">
                                            <a-button type="primary" @click="onSearchApparatus">搜索</a-button>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                                <a-col :span="16">
                                    <table class="layui-table" lay-size="sm">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>仪器编号</th>
                                            <th>仪器名称</th>
                                            <th>型号</th>
                                            <th>所属分类</th>
                                            <th>有效期</th>
                                            <th>状态</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item,key) in Apparatus_list_list" v-if="!item.selected && item.unSelectedShow">
                                            <td>
                                                <a-checkbox v-model="item.check"></a-checkbox>
                                            </td>
                                            <td>{{ item.serial_num }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.model }}</td>
                                            <td>{{ item.type_name }}</td>
                                            <td>{{ item.effect_time }}</td>
                                            <td>{{ item.used_status_trun }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <!------------------------------------------参数配置
                        ----------------------------------->
                        <a-tab-pane key="3" tab="参数配置" :disabled="tab_disabled">
                            <a-row>
                                <a-col :span="12">
                                    <a-form>

                                        <a-row>
                                            <a-col :span="5">
                                                <a-form-item label="是否包含曲线" :label-col="{ span: 10 }" :wrapper-col="{ span: 8,offset: 1 }">
                                                    <a-switch v-model="formA.is_curve"/>
                                                </a-form-item>
                                            </a-col>
                                            <!--                                            <a-col :span="8">-->
                                            <!--                                                <a-form-item label="方法允差" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">-->
                                            <!--                                                    <a-input placeholder="请输入数值" v-model="formA.tolerance"></a-input>-->
                                            <!--                                                </a-form-item>-->
                                            <!--                                            </a-col>-->
                                            <a-col :span="7">
                                                <a-form-item label="结果类型" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
                                                    <a-select v-model="formA.result_type">
                                                        <a-select-option :value=0>请选择结果类型</a-select-option>
                                                        <a-select-option :value=1>定量（范围值）</a-select-option>
                                                        <a-select-option :value=2>定性</a-select-option>
                                                        <a-select-option :value=3>定量（具体值）</a-select-option>
                                                    </a-select>
                                                </a-form-item>
                                            </a-col>
                                            <div v-if="formA.result_type == 1">
                                                <a-col :span="4">
                                                    <a-form-item label="上限" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                                                        <a-input placeholder="请输入数值" v-model="formA.upper"></a-input>
                                                    </a-form-item>
                                                </a-col>
                                                <a-col :span="4">
                                                    <a-form-item label="下限" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                                                        <a-input placeholder="请输入数值" v-model="formA.limit"></a-input>
                                                    </a-form-item>
                                                </a-col>
                                                <a-col :span="4">
                                                    <a-form-item label="单位" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                                                        <a-auto-complete v-model="formA.factor_unit_name" :data-source="dataSource.UnitName" placeholder="请输入单位" @search="onSearchFactorUnitName"/>
                                                    </a-form-item>
                                                </a-col>
                                            </div>
                                            <div v-if="formA.result_type == 2">
                                                <a-col :span="5">
                                                    <a-form-item label="" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}">
                                                        <a-input placeholder="请输入内容" v-model="formA.bz_value"></a-input>
                                                    </a-form-item>
                                                </a-col>
                                            </div>
                                            <div v-if="formA.result_type == 3">
                                                <a-col :span="3">
                                                    <a-form-item label="" :label-col="{ span: 8 }" :wrapper-col="{ span: 22}">
                                                        <a-select v-model="formA.bj">
                                                            <a-select-option value="≤">≤</a-select-option>
                                                            <a-select-option value="<"><</a-select-option>
                                                            <a-select-option value="≥">≥</a-select-option>
                                                            <a-select-option value=">">></a-select-option>
                                                            <a-select-option value="=">=</a-select-option>
                                                        </a-select>
                                                    </a-form-item>
                                                </a-col>
                                                <a-col :span="5">
                                                    <a-form-item label="" :label-col="{ span: 8 }" :wrapper-col="{ span: 22}">
                                                        <a-input placeholder="请输入数值" v-model="formA.bz_value"></a-input>
                                                    </a-form-item>
                                                </a-col>
                                                <a-col :span="4">
                                                    <a-form-item label="单位" :label-col="{ span: 5 }" :wrapper-col="{ span: 18}">
                                                        <a-auto-complete v-model="formA.factor_unit_name" :data-source="dataSource.UnitName" placeholder="请输入单位" @search="onSearchFactorUnitName"/>
                                                    </a-form-item>
                                                </a-col>
                                            </div>
                                        </a-row>
                                    </a-form>
                                    <a-row>
                                        <a-col :span="12">
                                            <a-button type="danger" @click="delyiqitiaojianBatch">移除所选</a-button>
                                        </a-col>
                                        <a-col :span="12" style="text-align: right">
                                            <a-button type="primary" @click="YQTJ = true">实验条件</a-button>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                        <a-col :span="24">
                                            <table class="layui-table" lay-size="sm">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>实验条件</th>
                                                    <th>默认值</th>
                                                    <th>操作</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item,key) in Sampletypelist_list" :key="key" v-if="item.selected">
                                                    <td>
                                                        <a-checkbox v-model="item.select_check"></a-checkbox>
                                                    </td>
                                                    <td>{{ item.condition }}</td>
                                                    <td>
                                                        <a-input v-model="item.condition_value" style="width: 100px"></a-input>
                                                    </td>
                                                    <td>
                                                        <a-button type="danger" size="small" @click="delyiqitiaojian(item.id)">
                                                            移除
                                                        </a-button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <a-button type="primary" @click="doClickC">保存</a-button>
                                        </a-col>
                                    </a-row>
                                </a-col>

                                <a-col :span="12">
                                    <div v-if="YQTJ">
                                        <a-row>
                                            <a-col :span="8" offset="1">
                                                <table class="layui-table" lay-size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>实验条件</th>
                                                        <th>默认值</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item,key) in Sampletypelist_list" :key="key" v-if="!item.selected">
                                                        <td>
                                                            <a-checkbox v-model="item.unselect_check"></a-checkbox>
                                                        </td>
                                                        <td>{{ item.condition }}</td>
                                                        <td>{{ item.default_value }}</td>
                                                        <td>
                                                            <a-button type="primary" size="small" @click="addyiqitiaojian(item.id)">
                                                                添加
                                                            </a-button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </a-col>
                                        </a-row>
                                    </div>
                                </a-col>

                            </a-row>
                        </a-tab-pane>
                        <!------------------------------------------检验模板
                        ----------------------------------->
                        <a-tab-pane key="5" tab="原始记录单模板" :disabled="tab_disabled">
                            <tplCom :edit_infoNewItem="edit_infoNewItem1" :reloadlist="reloadlist"></tplCom>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="检验模板" :disabled="tab_disabled">
                            <CheckTpl :tabActiveKey="tabActiveKey" :edit_infoNewItem="edit_infoNewItem1" :reloadlist="reloadlist"></CheckTpl>
                        </a-tab-pane>
                        <a-tab-pane key="6" tab="实验描述" :disabled="tab_disabled">
                            <a-textarea v-model="item_described"/>
                            <a-button class="mt-3" type="primary" @click="savedescribed()">保存</a-button>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </a-drawer>

        <a-drawer placement="bottom" height="800px" title="仪器模板" :visible="edit_yiqi" @close="yiqihandleCancel">
            <div>
                <YiqiTable :info="yiqiEditInfo"></YiqiTable>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    import tplCom from './tpl.vue';
    import CheckTpl from "./check_tpl";
    import YiqiTable from '../../../../components/drawer/shebeishow/yiqimoban/yiqimoban.vue'

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_infoNewItem', 'reloadlist', 'method_id'],
        components: {tplCom, CheckTpl, YiqiTable},
        data: () => ({
            tableData: [],//实验环境列表
            item_described: '',
            edit_yiqi: false,
            tab_disabled: true,
            formA: {
                id: 0,
                sample_type_ids: [],
                status: "1"
            },
            Check_bz_list_list: [],
            Method_category_alllist_list: [],
            tabActiveKey: '1',

            YQTJ: false,
            SearchApparatus: {
                type: 0,
                type_name: ''
            },
            //所有设备列表
            Apparatus_list_list: [],
            //仪器所有类别
            ApparatusClassify: [],

            info_YQTJ: {
                apparatus_id: '',
                jc_item_id: '',
            },


            yiqiEditInfo: false,
            edit_infoNewItem1: false,
            check: false,
            checked: false,
            // 参数配置部分
            TJYQ: false,
            condition_value: '',
            Sampletypelist_list: [],
            //自动完成数据
            autoData: {
                ItemName: [], //检验项目
                MethodName: [],//检验方法
                XZSerial: [],//检测标准细则
                CheckBZName: [],//检测标准名称
                UnitName: []// 单位
            },
            arr: [],
            //自动完成筛选
            dataSource: {
                ItemName: [],//检验项目
                MethodName: [],//检验方法
                XZSerial: [],//检测标准细则
                UnitName: [],// 单位
                MethodCategory: []
            },
            UnitList: [],//单位类表

            //默认选项
            defaultValue: {
                ApparatusCheckAll: false,
                MethodCategoryIds: []
            },
            ini_count: 0,//初始化个数记录

            zizhi_item_list: [],
            //保存已添加的参数-实验条件-id和value
            jc_pz_ex_environ: []
        }),
        watch: {
            ini_count(new_value) {
                if (new_value === 7) {
                    //console.log('ini_countini_countini_countini_count')
                    this.onSelectCheckBZName(this.formA.check_bz_name)
                    this.onDrawerOpen()
                }
            },
            visible(new_value) {
                if (new_value) {
                    this.Get_jc_pz_ex_environ()
                    this.formA = this.edit_infoNewItem
                    this.edit_infoNewItem1 = JSON.parse(JSON.stringify(this.edit_infoNewItem))
                    if (this.edit_infoNewItem.id > 0) {
                        this.tab_disabled = false
                        this.item_described = this.edit_infoNewItem.described
                    } else {
                        this.tab_disabled = true
                        this.formA.upper = null;
                        this.formA.limit = null;
                        this.formA.factor_unit_name = null
                        this.formA.bj = null
                    }
                    this.formA.status = this.edit_infoNewItem.status === 1;
                    if (this.edit_infoNewItem.is_curve === null || this.edit_infoNewItem.is_curve === null) {
                        this.formA.is_curve = false
                    } else {
                        this.formA.is_curve = true
                    }
                    this.formA.result_type = !this.edit_infoNewItem.result_type ? 0 : this.edit_infoNewItem.result_type
                    this.formA.bj = this.edit_infoNewItem.bj === null ? '>=' : this.edit_infoNewItem.bj
                    this.formA.factor_unit_name = this.edit_infoNewItem.factor_unit_name === null ? '' : this.edit_infoNewItem.factor_unit_name
                    console.log('this.formA', this.formA)
                    if (this.edit_infoNewItem.method_category_ids !== undefined) {
                        this.defaultValue.MethodCategoryIds = (this.edit_infoNewItem.method_category_ids).toString().split(',').map(i => Number(i))
                    } else {
                        this.defaultValue.MethodCategoryIds = []
                    }
                    console.log('******************************************')
                    console.log(this.formA)
                }
            },
        },
        mounted() {
            this.Check_bz_list()// w检测标准列表(不分页)
            this.Method_category_alllist()// w方法类别列表
            this.Apparatus_list()// w仪器列表
            this.Sampletypelist()//仪器条件
            this.get_jc_item_list()//检验项目 检测方法  检测标准细则 去重自动完成
            this.get_UnitList() //获取检测结果单位 自动完成
            this.Sampletypelist1() //资质

            this.edit_infoNewItem1 = JSON.parse(JSON.stringify(this.edit_infoNewItem))
            this.formA.id = this.edit_infoNewItem.id
            this.formA.item_name = this.edit_infoNewItem.item_name;
            this.formA.method_category_id = this.edit_infoNewItem.method_category_id;
            this.formA.method_name = this.edit_infoNewItem.method_name;
            this.formA.check_bz_id = this.edit_infoNewItem.check_bz_id;
            this.formA.xz_serial = this.edit_infoNewItem.xz_serial;
            // this.formA.status = this.edit_infoNewItem.status;
            this.formA.remark = this.edit_infoNewItem.remark;


            console.log(888888, this.$route.params.id)
            console.log(6352, this.method_id)

        },
        methods: {
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                );
            },
            onSearch1(searchText) {
                this.$sa0.post({
                    url: this.$api('Get_jc_item_item_name'),
                    data: {
                        item_name: searchText
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.dataSource.ItemName = response.data.list_item_name.map(item => item.item_name)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            onSearch2(searchText) {
                this.$sa0.post({
                    url: this.$api('Get_jc_item_method_name'),
                    data: {
                        method_name: searchText
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if (response.data.list_method_name.length > 0) {
                                this.dataSource.MethodName = response.data.list_method_name.filter(item => item.method_name).map(i => i.method_name)
                            } else {
                                this.dataSource.MethodName = []
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            onSearch3(searchText) {
                this.$sa0.post({
                    url: this.$api('Get_jc_item_xz_serial'),
                    data: {
                        xz_serial: searchText
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // this.dataSource.XZSerial = response.data.list_xz_serial.map(item => item.xz_serial)

                            if (response.data.list_xz_serial.length > 0) {
                                this.dataSource.XZSerial = response.data.list_xz_serial.filter(item => item.xz_serial).map(i => i.xz_serial)
                            } else {
                                this.dataSource.MethodName = []
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onSearch4(searchText) {
                this.$sa0.post({
                    url: this.$api('Check_bz_name'),
                    data: {
                        check_bz_name: searchText
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.dataSource.CheckBZName = response.data.list.map(item => item.name)

                            // if (response.data.list.length > 0) {
                            //     this.dataSource.CheckBZName = response.data.list.filter(item => item.name).map(i => i.name)
                            // } else {
                            //     this.dataSource.MethodName = []
                            // }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //获取参数配置实验条件保存列表
            Get_jc_pz_ex_environ() {

                this.$sa0.post({
                    url: this.$api('Get_jc_pz_ex_environ'),
                    data: {
                        jc_item_id: this.edit_infoNewItem.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.jc_pz_ex_environ = response.data
                            this.ini_count++
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            selectUpdate(e) {
                console.log(e)
                this.formA.sample_type_ids = e
            },
            yiqihandleCancel() {
                this.edit_yiqi = false
            },
            edityiqi(id) {
                this.edit_yiqi = false
                this.yiqiEditInfo = {id, item_id: this.edit_infoNewItem.id}
                setTimeout(() => {
                    this.edit_yiqi = true
                })
            },
            savedescribed() {
                this.$sa0.post({
                    url: this.$api('检测项目保存实验描述'),
                    data: {
                        id: this.edit_infoNewItem.id,
                        described: this.item_described,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('保存成功')
                            this.reloadlist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //----------------------------------------------设置仪器条件----------------------------------//

            delyiqitiaojianBatch() {
                this.Sampletypelist_list.map((item) => {
                    if (item.select_check) {
                        item.selected = false
                        item.select_check = false
                        item.condition_value = ''
                    }
                })
            },
            delyiqitiaojian(id) {
                this.Sampletypelist_list.map((item) => {
                    if (item.id === id) {
                        item.selected = false
                        item.select_check = false
                        item.condition_value = ''
                    }
                })
            },

            addyiqitiaojian(id) {
                this.Sampletypelist_list.map((item) => {
                    if (item.id === id) {
                        item.selected = true
                    }
                })
                console.log(this.Sampletypelist_list)
            },

            //仪器条件
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Get_ex_environ'),
                    data: {
                        // type: 13
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.ini_count++
                            this.Sampletypelist_list = response.data.result.list.map((item) => {
                                item.unselect_check = false
                                item.select_check = false
                                item.selected = false
                                item.condition_value = item.default_value
                                return item;
                            });
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //资质列表
            Sampletypelist1() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 6
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.zizhi_item_list = response.data.list

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //----------------------------------------------检验仪器----------------------------------//
            delSelectedyiqi() {
                let ids = []
                this.Apparatus_list_list.map(item => {
                    if (item.selected && item.check) {
                        ids.push(item.id)
                    }
                })
                if (ids.length < 1) {
                    layer.msg('没有选择要移除的仪器')
                    return
                }
                this.doDelyiqi(ids)
            },
            delyiqi(id) {
                let ids = [id]
                this.doDelyiqi(ids)
            },
            doDelyiqi(ids) {
                this.$sa0.post({
                    url: this.$api('Del_jc_item_apparatus'),
                    data: {
                        apparatus_id: ids.join(','),
                        jc_item_id: this.formA.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Apparatus_list_list.map(i => {
                                if (ids.indexOf(i.id) > -1) {
                                    i.selected = false
                                }
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },

                    })
                })
            },
            addyiqi() {
                let apparatus_id = []
                this.Apparatus_list_list.map((item) => {
                    if (item.selected) {
                        apparatus_id.push(item.id)
                    }
                    if (item.check) {
                        item.selected = true
                        item.check = false
                        apparatus_id.push(item.id)
                    }
                })
                if (apparatus_id.length === 0) {
                    layer.msg('未选中任何仪器')
                    return false
                }
                let data = {
                    apparatus_id: apparatus_id.join(','),
                    jc_item_id: this.formA.id,
                }
                this.$sa0.post({
                    url: this.$api('create_jc_item_apparatus'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')
                            //this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //仪器列表
            Apparatus_list() {
                this.$sa0.post({
                    url: this.$api('Apparatus_list'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.ini_count++
                            this.ApparatusClassify.push({
                                id: 0,
                                title: '请选择仪器分类'
                            })
                            let inApparatusClassify = []
                            this.Apparatus_list_list = response.data.list.map((item) => {
                                if (inApparatusClassify.indexOf(item.type) === -1) {
                                    inApparatusClassify.push(item.type)
                                    this.ApparatusClassify.push({
                                        id: item.type,
                                        title: item.type_name
                                    })
                                }
                                item.check = false
                                item.unSelectedShow = true
                                item.selected = false
                                return item;
                            });
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //----------------------------------------------基础信息----------------------------------//
            doClickA() {
                let data = {
                    jc_item_id: this.formA.id,
                    item_name: this.formA.item_name,
                    method_category_id: this.formA.method_category_id,
                    method_name: this.formA.method_name,
                    check_bz_id: this.formA.check_bz_id,
                    xz_serial: this.formA.xz_serial,
                    status: this.formA.status ? '1' : '2',
                    remark: this.formA.remark,
                    sample_type_ids: this.formA.sample_type_ids.join(','),
                    det_item_id: this.method_id,
                }
                console.log("===================================================================")
                console.log(this.$route.params.id)
                let api_url = this.formA.id > 0 ? 'Edit_jc_item' : 'Create_jc_item'
                this.$sa0.post({
                    url: this.$api(api_url),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')
                            this.formA.id = res.data.id
                            this.edit_infoNewItem1 = JSON.parse(JSON.stringify(res.data.jc_item))
                            setTimeout(() => {
                                this.tab_disabled = false
                            })
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            //方法类别列表
            Method_category_alllist() {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Method_category_alllist_list = response.data.list
                            for (let i = 0; i < response.data.list.length; i++) {
                                this.dataSource.MethodCategory.push(this.optionRecursive(this.Method_category_alllist_list[i]))
                            }
                            this.ini_count++
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //检测标准列表
            Check_bz_list() {
                this.$sa0.post({
                    url: this.$api('Check_bz_list'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Check_bz_list_list = response.data.list;
                            this.ini_count++
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //检验项目 检测方法  检测标准细则 去重自动完成
            get_jc_item_list() {
                this.$sa0.post({
                    url: this.$api('Get_jc_item_list'),
                    data: {},
                }).then((response) => {
                    this.ini_count++
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.autoData.ItemName = response.data.list_item_name.map(item => item.item_name)
                            this.autoData.MethodName = response.data.list_method_name.map(item => item.method_name)
                            this.autoData.XZSerial = response.data.list_xz_serial.map(item => item.xz_serial)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //获得所有单位列表 自动完成
            get_UnitList() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {type: 10},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.ini_count++
                            this.UnitList = response.data.list
                            this.autoData.UnitName = response.data.list.map(item => item.name)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //自动填充
            onSearchItemName(searchText) {
                this.dataSource.ItemName = !searchText ? [] : this.autoData.ItemName.filter(item => item.indexOf(searchText) > -1)
            },
            onSearchMethodName(searchText) {
                this.dataSource.MethodName = !searchText ? [] : this.autoData.MethodName.filter(item => item.indexOf(searchText) > -1)
            },
            onSearchXZSerial(searchText) {
                this.dataSource.XZSerial = !searchText ? [] : this.autoData.XZSerial.filter(item => {
                    if (item) {
                        return item.indexOf(searchText) > -1
                    }
                })
            },
            onSearchCheckBZName(searchText) {
                let bzName = this.Check_bz_list_list.filter(item => item.name.indexOf(searchText) > -1).map(item => item.name)
                this.dataSource.CheckBZName = [...new Set(bzName)]
            },
            //选择检测标准名称
            onSelectCheckBZName(value) {
                let find = this.Check_bz_list_list.find(item => item.name === value)
                if (find) {
                    this.$set(this.formA, 'check_bz_id', find.id)
                    this.$set(this.formA, 'serial', find.serial)
                } else {
                    this.$set(this.formA, 'check_bz_id', 0)
                    this.$set(this.formA, 'serial', '')
                }
            },
            //递归获取方法类别
            optionRecursive(data) {
                console.log(data + '---' + data.child.length)
                let result = {}
                result.children = []
                result.value = data.id
                result.label = data.name
                for (let i = 0; i < data.child.length; i++) {
                    result.children.push(this.optionRecursive(data.child[i]))
                }
                return result
            },
            onChangeMethodCategory(method_category_ids) {
                this.formA.method_category_id = method_category_ids[method_category_ids.length - 1]
            },
            //打开弹窗初始数据
            onDrawerOpen() {
                this.SearchApparatus.type = 0
                this.SearchApparatus.type_name = ''
                this.tabActiveKey = '1'
                this.defaultValue.ApparatusCheckAll = false
                this.TJYQ = false
                this.YQTJ = false
                let apparatus_ids = []
                if (this.formA.apparatus_id) {
                    apparatus_ids = this.formA.apparatus_id.split(',').map(item => Number(item))
                }
                this.Apparatus_list_list.map((item) => {
                    item.check = false
                    if (apparatus_ids.indexOf(item.id) > -1) {
                        item.selected = true
                    } else {
                        item.selected = false
                    }
                })

                this.Sampletypelist_list = this.Sampletypelist_list.map(item => {
                    let choose = this.jc_pz_ex_environ.find(i => i.sampletype_id == item.id)
                    if (choose === undefined) {
                        item.selected = false
                        item.condition_value = item.default_value
                    } else {
                        item.unselect_check = false
                        item.select_check = false
                        item.selected = true
                        item.condition_value = choose.condition_value
                    }
                    return item
                })

                let ids = this.formA.sample_type_ids
                if (ids) {
                    this.formA.sample_type_ids = ids.split(',').map((i) => Number(i))
                    this.arr = this.formA.sample_type_ids
                } else {
                    this.formA.sample_type_ids = []
                    this.arr = this.formA.sample_type_ids
                }
            },
            //全选已添加仪器
            ApparatusCheckAll() {
                let is_check;
                if (this.defaultValue.ApparatusCheckAll) {
                    is_check = false
                    this.defaultValue.ApparatusCheckAll = false
                } else {
                    is_check = true
                    this.defaultValue.ApparatusCheckAll = true
                }
                this.Apparatus_list_list.map(item => {
                    if (item.selected) {
                        item.check = is_check
                    }
                })
            },
            //切换tab
            onTableChange(key) {
                this.tabActiveKey = key
            },
            //搜索仪器
            onSearchApparatus() {
                // serial_num
                // name
                if (this.SearchApparatus.type === 0 && this.SearchApparatus.type_name === '') {
                    this.Apparatus_list_list.map(item => {
                        if (!item.selected) {
                            item.unSelectedShow = true
                        }
                    })
                } else {
                    this.Apparatus_list_list.map(item => {
                        let show = true;
                        if (!item.selected) {
                            if (this.SearchApparatus.type !== 0 && item.type !== this.SearchApparatus.type) {
                                show = false
                            }
                            if (item.serial_num.indexOf(this.SearchApparatus.type_name) === -1 && item.name.indexOf(this.SearchApparatus.type_name) === -1) {
                                show = false
                            }
                            item.unSelectedShow = show
                            console.log(item)
                        }
                    })
                }
            },
            onSearchFactorUnitName(searchText) {
                this.dataSource.UnitName = !searchText ? [] : this.autoData.UnitName.filter(item => item.indexOf(searchText) > -1)
            },
            //搜索单位
            //保存参数配置
            doClickC() {
                this.checkNullOrEmpty()
                let data = {}
                data.jc_item_id = this.formA.id
                data.is_curve = this.formA.is_curve ? 1 : 2
                data.tolerance = this.formA.tolerance
                data.result_type = this.formA.result_type
                if (this.formA.result_type == 1) {
                    if (this.formA.upper.length === 0) {
                        layer.msg('请输入上限')
                        return
                    } else {
                        data.upper = this.formA.upper
                    }
                    if (this.formA.limit.length === 0) {
                        layer.msg('请输入下限')
                        return
                    } else {
                        data.limit = this.formA.limit
                    }
                    if (this.formA.factor_unit_name.length === 0) {
                        layer.msg('请输入单位')
                        return
                    } else {
                        data.factor_unit_name = this.formA.factor_unit_name
                    }
                } else if (this.formA.result_type == 2) {
                    if (this.formA.bz_value.length === 0) {
                        layer.msg('请输入值')
                        return
                    } else {
                        data.bz_value = this.formA.bz_value
                    }
                } else if (this.formA.result_type == 3) {
                    data.bj = this.formA.bj
                    if (this.formA.bz_value.length === 0) {
                        layer.msg('请输入值')
                        return
                    } else {
                        data.bz_value = this.formA.bz_value
                    }
                } else {
                    layer.msg('请选择结果类型')
                    return
                }
                data.factor_unit_id = this.formA.factor_unit_name
                this.UnitList.map(item => {
                    if (item.name === this.formA.factor_unit_name) {
                        data.factor_unit_id = item.id
                        return
                    }
                })

                data.condition_content = []
                this.Sampletypelist_list.map((item) => {
                    if (item.selected) {
                        data.condition_content.push({
                            sampletype_id: item.id,
                            condition_value: item.condition_value,
                            jc_item_id: this.formA.id
                        })
                    }
                })
                // Create_jc_item_pz
                this.$sa0.post({
                    url: this.$api('Create_jc_item_pz'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('已保存')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            },
            checkNullOrEmpty() {
                console.log(this.formA)
                let keys = Object.keys(this.formA)
                for (let key of keys) {
                    if (this.formA[key] === null) {
                        this.formA[key] = ''
                    }
                }
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .nav-tab{ margin-top: -25px}
</style>
