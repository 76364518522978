<template>
  <div>
    <table class="layui-table" style="margin: 0;border: none;" lay-size="sm">
      <colgroup>
        <col width="119">
        <col width="300">
        <col width="80">
      </colgroup>
      <tbody>
      <tr v-for="(vi,vk) in info.content">
        <td>
          <span v-if="vk==info.content.length-1">结果</span>
          <span v-else>{{ vi.name }}{{ vk + 1 }}</span>
          <a-button @click="dval(vk)" v-if="vk!=0 && vk!=1 && vk!=info.content.length-1" type="danger" size="small">移除
          </a-button>
        </td>
        <td colspan="2">
          {{ xy(vi.xy) }}
        </td>
        <td>
          <a-space>
            <a-button @click="set(vk)" type="primary" size="small">设置</a-button>
            <a-button @click="del(vk)" type="danger" size="small">移除</a-button>
          </a-space>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <a-button @click="add()" type="primary" size="small">添加值</a-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "shizecha",
  props: ['info', 'xy', 'set', 'del', 'add', 'dval'],
}
</script>

<style scoped>

</style>
