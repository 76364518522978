<template>
    <div class="nav-tab">
        <a-tabs type="card" v-model="tab_active" @change="callback">
            <a-tab-pane :key="1" tab="按标准分类查看"></a-tab-pane>
            <a-tab-pane :key="2" tab="按方法分类查看"></a-tab-pane>
        </a-tabs>
        <a-row>
            <a-col :span="24" style="padding:0 0 15px 0">
                <a-form-model layout="inline" :modal="formSearch">
                    <a-form-model-item>
                        <a-input placeholder="请输入检测项目" v-model="formSearch.item_name" @keyup="Get_jc_itempage" style="width: 200px"></a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-input placeholder="请输入检测方法" v-model="formSearch.method_name" @keyup="Get_jc_itempage" style="width: 200px"></a-input>
                    </a-form-model-item>
                    <!--                    <a-form-model-item>-->
                    <!--                        <a-input placeholder="请输入标准细则" ></a-input>-->
                    <!--                    </a-form-model-item>-->
                    <a-form-model-item>
                        <a-radio-group v-model="formSearch.status" @change="Get_jc_itempage">
                            <a-radio :value="1">使用中</a-radio>
                            <a-radio :value="2">已停用</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-space>
                            <a-button type="danger" @click="Clear()">清空搜索</a-button>
                        </a-space>
                    </a-form-model-item>
                </a-form-model>
            </a-col>
        </a-row>
        <div class="wraper" style="margin-top: 0px;">
            <div class="wrap-mainer">
                <div class="sidebar">
                    <div v-if="tree_show">
                        <div v-if="tab_active === 1" class="tree_wrapperitem">
                            <div class="treetitle">标准分类</div>
                            <div class="treemain">
                                <ltree ref="ltreeref" w="225px" ot="only" :dt="0" :it="1" :data="tree" all="all" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback" :renameDo="renameDo"></ltree>
                            </div>

                        </div>

                        <div v-if="tab_active === 2" class="tree_wrapperitem">
                            <div class="treetitle">方法分类</div>
                            <ltree1 ref="ltreeref1" w="225px" ot="only" :dt="0" :it="1" :data="tree1" all="all" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback1" :renameDo="renameDo"></ltree1>
                        </div>
                    </div>
                </div>
                <div class="wrap-container">
                    <a-row>
                        <a-col :span="24" style="margin-bottom: -10px">
                            <a-space style="" class="btner">
                                <div class="btnerlist " @click="showDrawerNewItem(
                            {
                            id:0,
                             item_name:'',
                             method_category_id:'',
                             method_name:'',
                             check_bz_id:'',
                             serial:'',
                             xz_serial:'',
                             status:1,
                             remark:'',
                             xz_serial:'',
                             is_curve:true,
                             result_type:0,
                             upper:null,
                             limit:'',
                             factor_unit_name:'',
                             bj:'',
                             is_curve:'',
                             result_type:'',
                            }
                        )">
                                    <i class="iconfont icon-fuzhi1" style="font-size: 15px; vertical-align: -1px"></i>新建检测项目
                                </div>
                                <div class="btnerlist" @click="showDrawerNewItem()">
                                    <i class="iconfont icon-luruzhong" style="font-size: 15px; vertical-align: -1px"></i>编辑
                                </div>
                                <div class="btnerlist">
                                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick()">
                                        <template slot="title">确认删除该项？</template>
                                        <i class="iconfont icon-icon_xiaohuis" style="font-size: 18px; vertical-align: -2px"></i>删除
                                    </a-popconfirm>
                                </div>
                            </a-space>
                        </a-col>
                        <a-col :span="24">
                            <table class="layui-table" lay-size="sm" style="margin-top: 11px">
                                <thead>
                                <tr>
                                    <th>
                                        <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                                    </th>
                                    <th @click="paixuclickup(1)" style="cursor: pointer; ">
                                        <span>检测项目名称</span>
                                        <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 1 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 1 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                        </div>
                                    </th>
                                    <th @click="paixuclickup(2)" style="cursor: pointer; ">
                                        <span>所属分类</span>
                                        <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                        </div>
                                    </th>
                                    <th @click="paixuclickup(3)" style="cursor: pointer; ">
                                        <span>方法类别</span>
                                        <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                        </div>
                                    </th>
                                    <th @click="paixuclickup(4)" style="cursor: pointer; ">
                                        <span>检测方法</span>
                                        <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 4 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 4 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                        </div>
                                    </th>
                                    <th @click="paixuclickup(5)" style="cursor: pointer; ">
                                        <span>检测标准</span>
                                        <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 5 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 5 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                        </div>
                                    </th>
                                    <th>标准值</th>
                                    <th>单位</th>
                                    <th>备注</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in Get_jc_item_list" :key="key" @click="clickrow(key)">
                                    <td style="text-align: center">
                                        <a-checkbox v-model="item.check"></a-checkbox>
                                    </td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.det_item_name }}</td>
                                    <td>{{ item.method_category_name }}</td>
                                    <td>{{ item.method_name }}</td>
                                    <td><span>{{ item.check_bz_name }}--</span>
                                        <span> {{ item.xz_serial }}</span>
                                    </td>
                                    <td>{{ item.bz_value_str }}</td>
                                    <td>{{ item.factor_unit_name }}</td>
                                    <td>{{ item.remark }}</td>
                                </tr>
                                </tbody>
                            </table>

                            <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <div v-if="ddshow">
            <NewItem :method_id="method_id" :edit_infoNewItem="edit_infoNewItem" :reloadlist="Get_jc_item" :close="onCloseDrawerNewItem" :visible="drawer_visibleNewItem"></NewItem>
        </div>
    </div>
</template>
<script>
    import NewItem from './NewItem/NewItem.vue';
    // import Tree from './tree/tree.vue'
    import ltree1 from './ltree1/ltree.vue'
    import ltree from './ltree/ltree.vue'

    export default {
        name: "zhixingbiaozhun",
        components: {ltree, NewItem, ltree1},
        data() {
            return {
                tabid: '',
                tabid1: '',
                menu_arr: [],
                all_menu: [],
                method_id: 0,
                tree: [],
                tree1: [],
                menu: [],
                nav_show: true,
                tree_show: true,
                ddshow: false,
                formSearch: {
                    item_name: '',
                    method_name: '',
                    status: '',
                },
                Get_jc_item_list: [],
                tree_active_id: [],
                page: 1,
                count: 0,
                pagesize: 0,
                ordertype: 0,
                sortrule: 'asc',
                edit_infoNewItem: false,
                drawer_visibleNewItem: false,
                tab_active: '1',
                all_check: false,
                data_name: 0,
                data_sort: 'asc',
            }
        },
        watch: {
            $route: {
                handler() {
                    this.onMounted()//列表
                },
                deep: true,
            },

            itemActiveChange() {
                let do_ = true
                this.Get_jc_item_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_jc_item_list.map((item, key) => {
                    this.$set(this.Get_jc_item_list[key], 'check', this.all_check)
                })
            },
        },
        mounted() {
            this.onMounted()//列表
        },
        methods: {
            callback() {
                this.$router.push({
                    name: this.$route.name,
                    query: {type: this.tab_active, page: 1}
                })
            },
            onMounted() {
                let qid = Number(this.$route.query.id) || false;
                this.tab_active = Number(this.$route.query.type) || 1;
                this.page = Number(this.$route.query.page) || 1;

                console.log(qid)
                if (!qid || qid === 0) {
                    this.method_id = 0
                    this.Get_jc_item()
                }
                this.GMethod_category_alllist()
                this.Method_category_alllist()
                setTimeout(() => {
                    this.tree_show = true
                }, 100);
            },
            menuarrmaker() {
                this.all_menu = []
            },

            // 获取树执行标准列表
            GMethod_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Detitemalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list);
                            if (this.tab_active === 1) {
                                setTimeout(() => {
                                    this.$refs['ltreeref'].openGroup(this.tree_active_id)
                                }, 100)
                            }


                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Method_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree1 = this.makeList(response.data.list);
                            this.tree_show = true
                            if (this.tab_active === 2) {
                                setTimeout(() => {
                                    this.$refs['ltreeref1'].openGroup(this.tree_active_id)
                                }, 100)
                            }

                            this.menuarrmaker()

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            renameDo(item, name) {
                this.$sa0.post({
                    url: this.$api('Rename_detitem'),
                    data: {
                        name: name,
                        id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rename = false;
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                            this.$refs['ltreeref'].renameActive('no')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatfileall() {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建文件夹',
                        pid: 0,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatfilemain(item) {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建文件夹1',
                        pid: item.id,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatcontant(item) {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建执行标准名称',
                        pid: item.id,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            UPitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_detitemsort'),
                    data: {
                        det_item_id: item.id,
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Downitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_detitemsort'),
                    data: {
                        det_item_id: item.id,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delclick(item) {
                this.$confirm({
                    title: '确认删除该项?',
                    content: '',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        let data = {
                            id: item.id,
                        }
                        this.$sa0.post({
                            url: this.$api('Del_detitem'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg(res.message)
                                    this.GMethod_category_alllist()
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    },
                    onCancel() {
                    },
                });
            },

            treeCallback(item) {
                let info = item[item.length - 1]
                this.tabid = item[item.length - 1]
                if (info.id === 'all') {
                    this.$router.push({
                        name: this.$route.name,
                        query: {id: this.tabid.id, type: this.tab_active}
                    })
                    return
                }
                if (info.id !== 'all' && info.type === 1) {
                    this.$router.push({
                        name: this.$route.name,
                        query: {id: this.tabid.id, type: this.tab_active}
                    })
                }
                return;

            },
            treeCallback1(item) {
                let info = item[item.length - 1]
                this.tabid = item[item.length - 1]
                if (info.id === 'all') {
                    this.$router.push({
                        name: this.$route.name,
                        query: {id: this.tabid.id, type: this.tab_active}
                    })
                    return
                }
                if (info.id !== 'all' && info.type === 1) {
                    this.$router.push({
                        name: this.$route.name,
                        query: {id: this.tabid.id, type: this.tab_active}
                    })
                }
                return;
            },
            getMethodInfo(id) {
                this.method_id = id === 'all' ? '' : id
                this.Get_jc_item()
            },

            getMethodInfo1(id) {
                this.method_id1 = id === 'all' ? '' : id
                this.Get_jc_item()
            },

            makeList(list, pid = []) {
                let qid = Number(this.$route.query.id) || 0;
                return list.map((item) => {
                    // let
                    if (qid && qid === Number(item.id)) {
                        this.tree_active_id = [...pid, item.id]
                        this.deep_info = item
                        this.item_show = false
                        if (this.tab_active === 1) {
                            setTimeout(() => {
                                this.item_show = true
                                setTimeout(() => {
                                    this.getMethodInfo(item.id)

                                })
                            })
                        } else {
                            setTimeout(() => {
                                this.item_show = true
                                setTimeout(() => {
                                    this.getMethodInfo1(item.id)
                                })
                            })
                        }
                    }
                    return {
                        'id': Number(item.id),
                        "type": item.type,
                        "name": item.name,
                        "children": item.child.length === 0 ? [] : this.makeList(item.child, [...pid, item.id]),
                        "content": {
                            "pids": pid,
                            "pid": item.pid,
                        }
                    }
                })
            },

            toReload() {
                this.tree_show = false
                this.item_showall = false
                this.GMethod_category_alllist()
                setTimeout(() => {
                    this.tree_show = true
                })
            },
            //列表
            clickrow(key) {
                this.$set(this.Get_jc_item_list[key], 'check', !this.Get_jc_item_list[key].check)
                this.itemActiveChange()
            },
            // itemActiveChange() {
            //     let do_ = true
            //     this.Get_jc_item_list.map((item, key) => {
            //         if (!item.check) do_ = false
            //     })
            //     this.all_active = do_
            // },
            // allChange(e) {
            //     this.Get_jc_item_list.map((item, key) => {
            //         this.$set(this.Get_jc_item_list[key], 'active', this.all_active)
            //     })
            // },


            itemActiveChange() {
                let do_ = true
                this.Get_jc_item_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_jc_item_list.map((item, key) => {
                    this.$set(this.Get_jc_item_list[key], 'check', this.all_check)
                })
            },


            Get_jc_itempage() {
                this.page = 1
                this.Get_jc_item()
            },

            Get_jc_item() {
                console.log(11111112, this.method_id)
                console.log(11111112, this.method_id1)
                console.log(9999, this.$route.query.id)
                if (this.tab_active === 1) {
                    this.$sa0.post({
                        url: this.$api('Get_jc_item'),
                        data: {
                            item_name: this.formSearch.item_name,
                            method_name: this.formSearch.method_name,
                            status: this.formSearch.status,
                            page: this.page,
                            det_item_id: this.method_id === 0 ? '' : this.method_id,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_jc_item_list = response.data.result.list.map((item) => {
                                    item.check = false
                                    return item;
                                });
                                this.count = Number(response.data.result.count);//分页
                                this.pagesize = Number(response.data.result.pagesize);//分页\
                                if (this.edit_infoNewItem) {
                                    this.Get_jc_item_list.map((item) => {
                                        if (this.edit_infoNewItem.id === item.id) {
                                            this.edit_infoNewItem = item
                                        }
                                    })
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    this.$sa0.post({
                        url: this.$api('Get_jc_item'),
                        data: {
                            item_name: this.formSearch.item_name,
                            method_name: this.formSearch.method_name,
                            status: this.formSearch.status,
                            page: this.page,
                            method_category_id: this.$route.query.id === "all" ? '' : this.method_id1,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                // this.Get_jc_item_list = response.data.result.list;
                                this.Get_jc_item_list = response.data.result.list.map((item) => {
                                    item.check = false
                                    return item;
                                });
                                this.count = Number(response.data.result.count);//分页
                                this.pagesize = Number(response.data.result.pagesize);//分页
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }


            },

            paixuclickup(ordertype) {
                if (this.data_name !== ordertype) {
                    this.data_name = ordertype
                    this.data_sort = 'asc'
                } else {
                    if (this.data_sort === 'asc') {
                        this.data_sort = 'desc'
                    } else {
                        this.data_sort = 'asc'
                    }
                }

                if (this.tab_active === 1) {
                    this.$sa0.post({
                        url: this.$api('Get_jc_item'),
                        data: {
                            item_name: this.formSearch.item_name,
                            method_name: this.formSearch.method_name,
                            status: this.formSearch.status,
                            page: this.page,
                            det_item_id: this.method_id === 0 ? '' : this.method_id,
                            data_name: this.data_name,
                            data_sort: this.data_sort,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_jc_item_list = response.data.result.list
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                } else {
                    this.$sa0.post({
                        url: this.$api('Get_jc_item'),
                        data: {
                            item_name: this.formSearch.item_name,
                            method_name: this.formSearch.method_name,
                            status: this.formSearch.status,
                            page: this.page,
                            method_category_id: this.$route.query.id === "all" ? '' : this.method_id1,
                            data_name: this.data_name,
                            data_sort: this.data_sort,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_jc_item_list = response.data.result.list
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },


            //删除
            delClick() {
                let item_ids = []
                this.Get_jc_item_list.map((item) => {
                    if (item.check) {
                        item_ids.push(Number(item.id))
                    }
                })
                if (item_ids.length < 1) {
                    layer.msg('请勾选需要操作的项目')
                    return
                }
                this.$sa0.post({
                    url: this.$api('Del_jc_item'),
                    data: {
                        jc_item_id: item_ids.join(','),
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_jc_item()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 搜索
            searchClick() {
                this.page = 1;
                this.Get_jc_item();
            },
            Clear() {
                this.formSearch = {
                    item_name: '',
                    method_name: '',
                    status: '',
                }
                this.Get_jc_item();
            },
            // 分页
            onChange(page) {
                this.page = page;
                this.Get_jc_item();
            },
            // 定义 打开抽屉时的 新建/编辑
            showDrawerNewItem(form) {
                let info = {}
                if (form == undefined) {
                    let count = 0
                    this.Get_jc_item_list.map((item) => {
                        if (item.check) {
                            count++
                            info = item
                        }
                    })
                    if (count == 0) return layer.msg('请选择需要编辑的检测项目')
                    if (count > 1) return layer.msg('只能选择一个检测项目编辑')
                } else {
                    info = form;
                }
                this.ddshow = false;
                setTimeout(() => {
                    this.ddshow = true;

                    setTimeout(() => {
                        this.edit_infoNewItem = JSON.parse(JSON.stringify(info))
                        this.drawer_visibleNewItem = true;
                    })
                })
            },
            // 定义 关闭抽屉时的
            onCloseDrawerNewItem() {
                this.Get_jc_item();
                this.drawer_visibleNewItem = false;
            },

        },
        callback(key) {
            console.log(key);
        },
    }
</script>

<style scoped>
    /*******************w按钮区开始w*********************
        ***********************************************/
    .btner{
        width: 100%;
        background: #f3f3f3;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #e9e8e8;
        height: 26px;
        line-height: 24px;
        padding: 0 12px
        }

    .btner:last-child{
        border: none
        }

    .isthis, .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        height: 26px;
        line-height: 24px;
        }

    .btnerlist i{
        color: #999999;
        font-size: 16px;
        margin-right: 3px;
        }

    .isthis i, .btnerlist:hover i{
        color: #ffffff !important
        }

    .isDel, .isDel:hover{
        background: #ff4d4f;
        border-radius: 4px;
        color: #ffffff !important
        }

    .isDel i, .isDel:hover, .isDel:hover i{
        color: #ffffff !important
        }

    /*******************w按钮区结束w*********************
    ***********************************************/
    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .sidebar{
        width: 230px;
        display: flex;
        flex-direction: column;
        position: relative;
        }

    .wrap-container{
        flex: 1;
        overflow-y: auto;
        padding: 0 15px;
        }

    .tree_wrapperitem{
        position: absolute;
        top: 0;
        left: 0;
        height: 700px;
        margin-left: 0px;
        border: 1px solid #eeeeee;
        width: 227px;
        overflow: hidden;
        }

    /*.treeside{*/
    /*    position: absolute;*/
    /*    top:0;*/
    /*    left:0;*/
    /*    width: 230px;*/
    /*    height: calc(100vh - 110px);*/
    /*    border: 1px solid #eeeeee;*/
    /*    left: 0;*/
    /*    border-left: none*/
    /*}*/
    .treetitle{
        width: 230px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        background: #f9f9f9;
        padding-left: 10px;
        }

    .treemain{ width: 100%; height: 650px; overflow-y: auto}


    .left-menuer{
        border: 1px solid #eeeeee;
        overflow-y: auto;
        height: calc(100vh - 210px);
        }

    .treemain::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }

    .treemain::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 4px;
        background: #ccc;
        }

    .treemain::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        border-radius: 4px;
        background: #f9f9f9;
        }

    .w200{
        width: 200px
        }

    .w100{
        width: 100px
        }

    .ant-table-column-sorter{
        display: inline-flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 5px;
        cursor: pointer;
        width: 10px;
        vertical-align: middle;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .active{ background: #ebf6ff}

    table tr{ cursor: pointer}

</style>
