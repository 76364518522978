<script>
import Gsshizecha from './gongshi/shizecha.vue'
import Gspingjunzhi from './gongshi/pingjunzhi.vue'

export default {
  props: ['info'],
  components: {Gsshizecha, Gspingjunzhi},
  data() {
    return {
      gsid: 'avg',
      gs_list: [],
      gs_content: [],
      new_input_show: 0,
      iframe_show: false,
      url_p: {
        x: 5,
        y: 5,
        t: 1,
        i: 0,
      },
      url: '/yiqilucky.html',
      tr_arr: ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],

    }
  },
  watch: {
    info: {
      handler() {
        this.mountedDo()
      },
      deep: true,
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    mountedDo() {
      this.getShebeiInfo()
    },
    getShebeiInfo() {
      let id = this.info.id;
      this.new_input_show = 0
      this.$sa0.post({
        url: this.$api('获取仪器表格信息0309'),
        data: {
          id,
          item_id: this.info.item_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              this.$sa0.localStorage.set('Item_Table_Data', JSON.parse(response.data.info.content))
              this.url_p.t = response.data.info.id
              this.url_p.x = response.data.info.column
              this.url_p.y = response.data.info.row
            } else {
              this.url_p.t = 0
              this.url_p.x = 5
              this.url_p.y = 5
            }
            this.drawButtonClick()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    drawButtonClick(t = false) {
      this.iframe_show = false;
      if (t) this.url_p.t = 0;
      let p = this.url_p
      let p_arr = [];
      for (let i in p) {
        p_arr.push(`${i}=${p[i]}`)
      }
      this.url = '/yiqilucky.html?' + p_arr.join("&");
      setTimeout(() => {
        this.iframe_show = true;
      })
    },
    saveClick() {
      let content = document.getElementById('iframeid').contentWindow.app.getContent();
      let data = {
        id: this.info.id,
        item_id: this.info.item_id,
        content: JSON.stringify(content),
        column: this.url_p.x,
        row: this.url_p.y,
      }
      this.$sa0.post({
        url: this.$api('保存仪器表格信息0309'),
        data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getGongshiList() {
      this.$sa0.post({
        url: this.$api('获取公式列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gs_list = response.data.list
            this.gsid = this.gs_list[0]['id']
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    editClick(value) {
      if (this.new_input_show === value) {
        this.new_input_show = 0
      } else {
        this.new_input_show = value
        if (value === 3) {
          this.getGongshiList();
          this.getGscontent();
        }
      }
    },
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = JSON.parse(xy)
      let t1 = `${this.tr_arr[xy_.column[0] + 1]}${xy_.row[0] + 1}`;
      let t2 = `${this.tr_arr[xy_.column[1] + 1]}${xy_.row[1] + 1}`;
      if (t1 === t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    setGsXy(xy, gsk) {
      let range = document.getElementById('iframeid').contentWindow.app.getRange();
      console.log(range)
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.gs_content[gsk].content[xy].xy = range_str
    },
    addGscontent() {
      let gsid = this.gsid
      let gsname = ''
      let gs_content = []
      if (gsid === 'avg') {
        gsname = '平均值'
        gs_content = [{
          'xy': '',
          'name': '值',
        }, {
          'xy': '',
          'name': '值',
        }, {
          'xy': '',
          'name': '结果',
        }]
      } else {
        let content = [];
        this.gs_list.map((item) => {
          if (Number(item.id) === Number(gsid)) {
            gsname = item.name
            item.yinzi.map((i) => {
              content.push({
                'xy': '',
                'name': i.yinzi,
              })
            })
            content.push({
              'xy': '',
              'name': '结果',
            })
          }
        })
        gs_content = content
      }
      // xy
      this.gs_content.push({
        gsid: gsid,
        name: gsname,
        content: gs_content
      })
    },
    avgDelValue(gsk, key) {
      let content = JSON.parse(JSON.stringify(this.gs_content[gsk].content))
      content.splice(key, 1)
      this.gs_content[gsk].content = content
    },
    delGscontent(gsk) {
      let gs_content_turn = JSON.parse(JSON.stringify(this.gs_content))
      gs_content_turn.splice(gsk, 1)
      this.gs_content = gs_content_turn
    },
    avgAddValue(gsk) {
      this.gs_content[gsk].content
      let res = this.gs_content[gsk].content.pop()
      this.gs_content[gsk].content = [
        ...this.gs_content[gsk].content,
        {
          'xy': '',
          'name': '值',
        },
        res
      ]
    },
    getGscontent() {
      this.$sa0.post({
        url: this.$api('获取仪器公式信息0309'),
        data: {
          id: this.info.id,
          item_id: this.info.item_id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              this.gs_content = JSON.parse(response.data.info.content)
            } else {
              this.gs_content = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveGscontent() {
      this.$sa0.post({
        url: this.$api('保存仪器公式信息0309'),
        data: {
          id: this.info.id,
          item_id: this.info.item_id,
          content: JSON.stringify(this.gs_content)
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delGsXy(xy, gsk) {
      this.gs_content[gsk].content[xy].xy = ''
    },
  },
  name: "yiqimoban"
}
</script>
<template>
  <div class="edit_wrapper">
    <div>
      <a-space>
        <a-button type="primary" @click="editClick(1)">
          {{ new_input_show === 1 ? '取消编辑' : '编辑表格' }}
        </a-button>
        <!--        <a-button type="primary">从模板中选择</a-button>-->
        <a-button type="primary" @click="editClick(2)">
          {{ new_input_show === 2 ? '退出编辑' : '结果修约' }}
        </a-button>
        <a-button type="primary" @click="editClick(3)">
          {{ new_input_show === 3 ? '退出编辑' : '计算公式' }}
        </a-button>

      </a-space>
    </div>
    <div v-if="iframe_show">
      <iframe ref="iframeRef" id="iframeid" class="luckysheet_wrapper" :src="url" frameborder="0"></iframe>
    </div>
    <div class="mt-2 new_input_wrapper" v-if="new_input_show === 1">
      <a-space>
        <a-input v-model="url_p.x" style="width: 100px;"></a-input>
        <a-input v-model="url_p.y" style="width: 100px;"></a-input>
        <a-button type="primary" @click="drawButtonClick(true)">生成新表格</a-button>
        <a-button type="primary" @click="saveClick()">保存当前</a-button>
      </a-space>
      <div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th v-for="i in Number(url_p.x) + 1">{{ tr_arr[i - 1] }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in Number(url_p.y)">
            <td v-for="j in Number(url_p.x) + 1">
              <span v-show="j === 1">{{ i }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-2 new_input_wrapper" v-if="new_input_show === 2">
      结果修约
    </div>
    <div class="mt-2 new_input_wrapper" v-if="new_input_show === 3">
      <table class="layui-table" lay-size="sm">
        <tbody>
        <template v-for="(gsi,gsk) in gs_content">
          <tr>
            <td colspan="3">{{ gsi.name }}</td>
            <td>
              <a-button @click="delGscontent(gsk)" type="danger" size="small">移除</a-button>
            </td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 0">
              <Gspingjunzhi v-if="gsi.gsid==='avg'" :info="gsi" :xy="returnYpcontentXy"
                            :set="(xy)=>{setGsXy(xy,gsk)}" :dval="(e)=>{avgDelValue(gsk,e)}"
                            :del="(xy)=>{delGsXy(xy,gsk)}" :add="()=>{avgAddValue(gsk)}"></Gspingjunzhi>
              <Gsshizecha v-else :info="gsi" :xy="returnYpcontentXy" :set="(xy)=>{setGsXy(xy,gsk)}"
                          :del="(xy)=>{delGsXy(xy,gsk)}"></Gsshizecha>
            </td>
          </tr>
        </template>
        <tr>
          <td colspan="4">
            <a-space>
              <a-button @click="addGscontent()" type="primary" size="small">添加公式</a-button>
              <a-select style="width: 100%;" size="small" v-model="gsid">
                <a-select-option value="avg">平均值 <b>n=(n1+n2+n3...+nx)/x</b></a-select-option>
                <a-select-option v-for="(mi,mk) in gs_list" :value="mi.id">
                  {{ mi.name }} <b>{{ mi.formula }}</b>
                </a-select-option>
              </a-select>
            </a-space>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <a-button @click="saveGscontent()" type="primary" size="small">保存</a-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.edit_wrapper{
  height: 800px;
}
.new_input_wrapper {
  position: absolute;
  top: 100px;
  left: 1030px;
  right: 10px;
}

.luckysheet_wrapper {
  margin-top: 10px;
  vertical-align: top;
  display: inline-block;
  width: 1000px;
  height: calc(100vh - 400px);
}
</style>
