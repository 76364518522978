<script>
/***
 Name: yangpinedit
 Code: sa0ChunLuyu
 Time: 2022/3/10 10:18
 Remark:
 */
export default {
  props: ['yiqip_list', 'gs_list', 'addyiqi_content'],
  data() {
    return {
      tr_arr: ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      visible: false,
      value_type: [{
        'value': 0,
        'name': '数值',
      }, {
        'value': 1,
        'name': '仪器',
      }, {
        'value': 2,
        'name': '公式',
      }],
      son_type: [{
        'value': 0,
        'name': '数值',
      }, {
        'value': 1,
        'name': '仪器',
      }],
      yiqicontent_create: {
        ke: -1,
        xy: '',
        vt: 0,
        vl: [{
          v: ''
        }, {
          v: []
        }, {
          gs: 'avg'
        }]
      },
    }
  },
  methods: {
    createDo(info) {
      this.yiqicontent_create = info
      this.visible = true
    },
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = xy
      let t1 = `${this.tr_arr[xy_.column[0] + 1]}${xy_.row[0] + 1}`;
      let t2 = `${this.tr_arr[xy_.column[1] + 1]}${xy_.row[1] + 1}`;
      if (t1 === t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.addyiqi_content(this.yiqicontent_create)
      this.visible = false
    }
  }
}
</script>
<template>
  <div>
    <a-modal
        title="仪器数值编辑"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <div>
        <table class="layui-table" lay-size="sm">
          <tbody>
          <tr>
            <td>位置</td>
            <td>{{ returnYpcontentXy(yiqicontent_create.xy) }}</td>
          </tr>
          <tr>
            <td>数值类型</td>
            <td>
              <a-select v-model="yiqicontent_create.vt">
                <a-select-option :value="i.value" v-for="(i,k) in value_type">
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </td>
          </tr>
          <tr v-if="yiqicontent_create.vt === 0">
            <td>数值</td>
            <td>
              <a-input v-model="yiqicontent_create.vl[0].v"></a-input>
            </td>
          </tr>
          <tr v-if="yiqicontent_create.vt === 1">
            <td>仪器</td>
            <td>
              <a-cascader style="width: 100%;" :options="yiqip_list" v-model="yiqicontent_create.vl[1].v"/>
            </td>
          </tr>
          <tr v-if="yiqicontent_create.vt === 2">
            <td>公式</td>
            <td>
              <a-select style="width: 100%;" size="small" v-model="yiqicontent_create.vl[2].gs">
                <a-select-option value="avg">平均值 <b>n=(n1+n2+n3...+nx)/x</b></a-select-option>
                <a-select-option v-for="(mi,mk) in gs_list" :value="mi.id">
                  {{ mi.name }} <b>{{ mi.formula }}</b>
                </a-select-option>
              </a-select>
            </td>
          </tr>
          <tr v-if="yiqicontent_create.vt === 2 && yiqicontent_create.vl[2].gs == 'avg'">
            <td>公式</td>
            <td>公式</td>
            <td>公式</td>
            <td>公式</td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
  </div>
</template>
<style scoped>

</style>
